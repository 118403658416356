import React, {useState} from 'react';
import './App.css';
import Viewer from './components/viewer';
import Hud from './components/hud';
import User from './components/user';
import Dialog from './components/dialog';
import Login from './components/login';
import '@fortawesome/fontawesome-free/css/all.css'

import Cookies from 'universal-cookie';
import io from 'socket.io-client';


let u = localStorage.getItem('user') || null;
if(u) {
  u = JSON.parse(u);
  const cookies = new Cookies();
  let JWT = cookies.get('JWT');
  if(!JWT) {
    cookies.set('JWT', u.jwt, { path: '/' });
  }
}

const socket = io(window.location.origin,{
  path: '/api/fadesockets/',
  autoConnect: true,
  // transports: ['websocket']
});

socket.on('connect',()=>{
  console.log('connected');
  socket.emit('init');
});



// let coords = {x:0,y:0};

const App = () => {
  // const [time,setTime] = useState(1000);
  const [show,setShow] = useState(false);
  const [login,setLogin] = useState(false);
  const [item,setItem] = useState({});
  const [user,setUser] = useState(u);
  const addStory = () => {
    setShow(!show);
  }

  const addLogin = (data) => {
    if(data && data.userId) {
      console.log("set user:", data);
      setUser(data);
    }
    setLogin(!login);
  }

  socket.on('nextFade',(data)=>{
    console.log(data);
    // let nextFade = data - Date.now();
    // setTime(nextFade);
    // setTime(nextFade);
    // clearInterval(clock);
    // clock = setInterval(()=>{
    //   nextFade -= 1000;
    //   if(nextFade < 1000) {
    //     nextFade = 0;
    //     clearInterval(clock);
    //   }
    //   setTime(nextFade);
    // },1000);
  });

  const onAdd = (data) => {
    setItem(data);
  }

  return (
    <div className="App">
      <div className="leftMenu">
        <Hud />
      </div>
      <div className="topMenu">
        <User user={user} addStory={addStory} addLogin={addLogin}/>
      </div>
      <div className={(show || login)?"blur":""}>
        <Viewer socket={socket} newItem={item}/>
      </div>
      {(show === true)?<Dialog onClose={addStory} onAdd={onAdd}/>:null}
      {(login === true)?<Login onClose={addLogin}/>:null}
    </div>
  );
}

export default App;