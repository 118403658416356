import React from 'react';




const Hud = (props) => {
    return <>
        {/* <div className="iconWithText" ><img src="/images/clock.png" alt="time"/>  {formatTime(time)}</div> */}
        <div className="iconWithText" ><img src="/images/pin.png" alt="time"/> <span id="coordsHud"></span></div>
    </>
}

export default Hud;




// const formatTime = (ms) =>{
//     let date = new Date(null);
//     date.setMilliseconds(ms); // specify value for SECONDS here
//     let timeString = date.toISOString().substr(11, 8);
//     return timeString;
// }