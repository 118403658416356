import React, {useEffect} from 'react';
// import './style.css';
// import {Vector3} from 'three';
const Expand = (props) => {
    const {onClose, data} = props;

    useEffect(()=>{
        let el = document.getElementById('mediaContainer');
        let media = document.getElementById("item_"+data.id).cloneNode(true);
        media.style.transform = 'none';
        el.appendChild(media);
    },[data.id]);
   
    
    return <div id="myModal" className="modal">
                <div className="modal-content" style={{width:'80vw', height:'80vh'}}>
                    <span className="close" onClick={onClose}>&times;</span>
                   
                    <div id="mediaContainer">
                    </div>
                    {/* <div className="actions">
                        <div className="cancel" onClick={onClose}>
                            Cancel
                        </div>
                        <div className="next" onClick={next}>
                            Next
                        </div>
                    </div> */}
                </div>
            </div>
}

export default Expand;