import React, {useState} from 'react';
import './style.css';
import MyEditor from './editor';
import {coords} from '../../store/position';
// import {Vector3} from 'three';
const Dialog = (props) => {
    const {onClose, onAdd} = props;

    const [type,setType] = useState(null);
    const [url,setUrl] = useState('');
    const [error,setError] = useState('');
    const typeHandler = type => () => {
        console.log(type);
        setType(type);
    }

    const next = () =>  {
        console.log(url,type, coords);

        
        // var vec = new Vector3( coords.x, 200, coords.z );
        // vec.applyQuaternion( camera.quaternion );

        
        fetch(`/api/fade`, {
            method:'POST',
            body: JSON.stringify({url,type,lat:coords.x1,lon:coords.y1}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
        })
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
            if(data.message) {
                return setError(data.message);
            }
            if(onAdd  && data.data) {
                onAdd(data);
                onClose();
            }
		}).catch((err)=>{
            console.log(err, err.message);
            setError(err.message);
        });
    }
    
    return <div id="myModal" className="modal">
                <div className="modal-content">
                    {/* <span className="close" onClick={onClose}>&times;</span> */}
                    <p>Create Story</p>
                    <div className="links">
                        <span onClick={typeHandler('text')} className={(type==='text')?'selected':''}>
                            <i className="fas fa-font fa-3x"></i><br/>Text
                        </span>
                        <span onClick={typeHandler('music')} className={(type==='music')?'selected':''}>
                            <i className="fas fa-music fa-3x"></i><br/>Music
                        </span>
                        <span onClick={typeHandler('img')} className={(type==='img')?'selected':''}>
                            <i className="fas fa-file-image fa-3x"></i><br/>Album
                        </span>
                        <span onClick={typeHandler('link')} className={(type==='link')?'selected':''}>
                            <i className="fas fa-link fa-3x"></i><br/>Link
                        </span>
                        <span onClick={typeHandler('video')} className={(type==='video')?'selected':''}>
                            <i className="fas fa-video fa-3x"></i><br/>Video
                        </span>
                        <span onClick={typeHandler('camera')} className={(type==='camera')?'selected':''}>
                            <i className="fas fa-camera fa-3x"></i><br/>Selfie
                        </span>
                        <span onClick={typeHandler('upload')} className={(type==='upload')?'selected':''}>
                            <i className="fas fa-upload fa-3x"></i><br/>Upload
                        </span>
                    </div>
                    <div style={{height:190, overflowY:'auto'}}>
                        {(type && type !== 'upload' && type !== 'text' && type !== 'camera' )?
                        <input type="text" name="url" onChange={(e)=>{setUrl(e.target.value)}} placeholder="past url here" className={(error)?"urlinput errorInput":"urlinput"} />:null}
                        {error && <div className="errorText">{error}</div>}
                        {(type && type === 'text')?<MyEditor onChange={setUrl}/>:null}
                    </div>
                    <div className="actions">
                        <div className="cancel" onClick={onClose}>
                            Cancel
                        </div>
                        <div className="next" onClick={next}>
                            Next
                        </div>
                    </div>
                </div>
            </div>
}

export default Dialog;