import { CSS3DObject } from '../../three/CSS3DRenderer.js';
import './objects.css';
const scale = 0.25;

let loadtiktokembed;
let instaembed;
const tiktok = (item) => {
	const { lat: x, lon: y } = item;
	var div = document.createElement('div');
	div.className = 'fade-in';
	// let aspect = 1280/720;
	let width = 605;
	let height = 782;
	let halfScale = scale / 2;
	let middle = height * halfScale / 2;
	div.style.width = width + 'px';
	div.style.height = height + 'px';
	div.style.backgroundColor = '#fff';
	div.style.borderRadius = '20px';
	div.style.MozBorderRadius = '20px';
	div.style.overflow = 'hidden';
	// div.innerHTML = html;
	var observer = new MutationObserver(function() {
		observer.disconnect();
		div.innerHTML = item.data.html;
		clearTimeout(loadtiktokembed);
		loadtiktokembed = setTimeout(() => {
			let el = document.getElementById('tiktokembed');
			if (el) {
				el.remove();
			}
			var tag = document.createElement('script');
			tag.id = 'tiktokembed';
			tag.src = 'https://www.tiktok.com/embed.js';
			document.getElementsByTagName('head')[0].appendChild(tag);
		}, 100);
	});
	observer.observe(div, { attributes: true });
	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(halfScale, halfScale, halfScale);
	object.lookAt(0, middle, 0);
	return object;
};

const yt = (item) => {
	const { lat: x, lon: y } = item;
	const { id } = item.data;
	var div = document.createElement('div');
	div.className = 'fade-in';
	let h = 360;
	let middle = h * scale / 2;
	div.style.width = '480px';
	div.style.height = '360px';
	div.style.backgroundColor = '#000';
	div.style.borderRadius = '20px';
	div.style.MozBorderRadius = '20px';
	div.style.overflow = 'hidden';
	div.style.display = 'flex';
	div.style.alignItems = 'center';
	// div.innerHTML = `<img src="${item.thumbnails.high.url}" />`;
	var a = document.createElement('a');
	a.href = '#';
	var img = document.createElement('img');
	img.width = 70;
	img.height = 70;
	a.style.margin = 'auto';
	img.src = '/images/play.png';
	div.style.backgroundImage = `url('${item.url}')`;
	a.onclick = function(e) {
		let iframe = `<iframe width="480" height="360" src="https://www.youtube.com/embed/${id}?rel=0&autoplay=true" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
		div.innerHTML = iframe;
	};
	a.appendChild(img);


	div.appendChild(a);
	var container = document.createElement('div');
	container.style.position = 'relative';

	var comments = document.createElement('div');
	comments.className = 'comments';

	let box = `
		<div class="commentUser">
			<div class="avatar"></div>
			<div>UserName</div>
		</div>
		<div class="commentList">No Comments</div>			
		<div class="addComment">
			<input type="text" placeholder="Say something nice..." class="commentInput" />
		</div>
	`;
	comments.innerHTML = box;

	let t = item.data.title || item.url;
	var title = document.createElement('div');
	title.className = 'commentsTitle';
	title.innerHTML = `${t}<br/>`;

	container.appendChild(div);
	container.appendChild(comments);
	container.appendChild(title);
	var object = new CSS3DObject(container);
	object.position.set(x, middle, y);
	// object.rotation.y = ry;
	object.scale.set(scale, scale, scale);
	object.lookAt(0, middle, 0);
	return object;
};

const twitchChannel = function(item) {
	const { lat: x, lon: y } = item;
	const { id } = item.data;
	let h = 360;
	let middle = h * scale / 2;
	var div = document.createElement('div');
	div.id = 'twitch_' + id;
	div.className = 'fade-in';
	div.style.width = '480px';
	div.style.height = '360px';
	div.style.backgroundColor = '#000';
	div.style.borderRadius = '20px';
	div.style.MozBorderRadius = '20px';
	div.style.overflow = 'hidden';
	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(scale, scale, scale);
	var observer = new MutationObserver(function() {
		observer.disconnect();
		new window.Twitch.Embed(div.id, {
			width: 480,
			height: 360,
			channel: id,
			layout: 'video',
			autoplay: false
		});
	});
	observer.observe(div, { attributes: true });
	object.lookAt(0, middle, 0);
	return object;
};

const img = (item) => {
	const { lat: x, lon: y } = item;
	let { width, height } = item.data;
	let src = item.url;
	let aspect = height / width;
	let h = aspect * 400;
	let middle = h * scale / 2;
	var div = document.createElement('div');
	div.className = 'fade-in';
	div.width = '400px';
	div.height = h + 'px';
	div.style.overflow = 'hidden';
	div.style.borderRadius = '20px';
	div.innerHTML = `<img src="${src}" width="400"/>`;
	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(scale, scale, scale);

	object.lookAt(0, middle, 0);
	return object;
};

const ig = (item) => {
	const { lat: x, lon: y } = item;
	let { thumbnail_width: width, thumbnail_height: height, html } = item.data;
	let src = item.data.thumbnail_url;
	let aspect = height / width;
	let h = aspect * 400 + 130;
	let middle = h * scale / 2 + 5;
	var div = document.createElement('div');
	div.className = 'fade-in';
	div.width = '400px';
	div.height = h + 'px';
	div.style.overflow = 'hidden';
	div.style.backgroundColor = '#fff';
	div.style.borderRadius = '20px';
	div.innerHTML = `<img src="${src}" width="400"/>`;

	var observer = new MutationObserver(function() {
		observer.disconnect();
		div.innerHTML = html;
		clearTimeout(instaembed);
		instaembed = setTimeout(() => {
			if (window.instgrm) {
				window.instgrm.Embeds.process();
			} else {
				let el = document.getElementById('instaembed');
				if (el) {
					el.remove();
				}
				var tag = document.createElement('script');
				tag.id = 'instaembed';
				tag.src = '//www.instagram.com/embed.js';
				document.getElementsByTagName('head')[0].appendChild(tag);
			}
		}, 100);
	});
	observer.observe(div, { attributes: true });
	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(scale, scale, scale);

	object.lookAt(0, middle, 0);
	return object;
};

const sc = (item) => {
	const { url } = item;
	const { lat: x, lon: y } = item;
	let src = `https://w.soundcloud.com/player/?url=${url}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`;
	let h = 300;
	let middle = h * scale / 2;
	var div = document.createElement('div');
	div.className = 'fade-in';
	div.width = '400px';
	div.height = h + 'px';
	div.style.overflow = 'hidden';
	div.style.borderRadius = '20px';
	div.innerHTML = `<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="${src}"></iframe>`;
	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(scale, scale, scale);
	object.lookAt(0, middle, 0);
	return object;
};

const sp = (item) => {
	const { url } = item;
	const { lat: x, lon: y } = item;
	let src = url;
	let h = 480;
	let middle = h * scale / 2;
	var div = document.createElement('div');
	div.className = 'fade-in';
	div.width = '400px';
	div.height = h + 'px';
	div.style.overflow = 'hidden';
	div.style.borderRadius = '20px';
	div.innerHTML = `<iframe src="${src}" width="400" height="480" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(scale, scale, scale);
	object.lookAt(0, middle, 0);
	return object;
};

const text = (item) => {
	const { data } = item;
	const { lat: x, lon: y } = item;
	let h = 480;
	let middle = h * scale / 2;
	var div = document.createElement('div');
	div.className = 'fade-in';
	div.width = '400px';
	div.height = h + 'px';
	div.style.overflow = 'hidden';
	div.style.borderRadius = '20px';
	div.innerHTML = `<div style="width:400px; height:${h}px; overflow:auto; word-wrap: break-word;">${data}</div>`;
	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(scale, scale, scale);
	object.lookAt(0, middle, 0);
	return object;
};

const music = (item, onPlay) => {
	const { lat: x, lon: y, id, url } = item;
	const { artist, title } = item.data.common;
	let h = 400;
	let middle = h * scale / 2;
	var div = document.createElement('div');
	div.id = 'audio';
	div.className = 'fade-in';
	div.style.width = '400px';
	div.style.height = h + 'px';
	// div.style.overflow = 'hidden';
	// div.style.borderRadius = '20px';

	let tmp = `<div class="vz-wrapper">
					<audio id="audio_${id}" src="${url}" data-author="${artist}" data-title="${title}" data-x="${x}" data-y="${y}"></audio>
					<div class="vz-wrapper -canvas">
						<canvas id="canvas_${id}" width="400" height="400"></canvas>
					</div>
				</div>`;
	div.innerHTML = tmp;

	var observer = new MutationObserver(function() {
		observer.disconnect();
		setTimeout(() => {
			window.AUDIO.VISUALIZER.getInstance({
				audio: 'audio_' + id,
				canvas: 'canvas_' + id,
				autoplay: false,
				barColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
				onPlay: onPlay
			});
		}, 1000);
	});

	observer.observe(div, { attributes: true });

	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(scale, scale, scale);
	object.lookAt(0, middle, 0);
	object.rotateY(Math.PI);
	return object;
};

const link = (item) => {
	const { lat: x, lon: y, url } = item;
	const { image, title, description } = item.data;
	let width = 400;
	let height = 600;
	let aspect = height / width;
	let h = aspect * 400;
	let middle = h * scale / 2;
	var div = document.createElement('div');
	div.className = 'fade-in';
	div.width = '400px';
	div.height = h + 'px';
	div.style.overflow = 'hidden';
	div.style.borderRadius = '20px';
	// div.innerHTML = `<img src="${image}" width="400"  alt="${title}"/>`;

	let card = `<div class="card">
                    <a href="${url}" target="_blank">
                    <img class="card-img-top" src="${image}" alt="${title}">
                    <i class="fas fa-link fa-5x linkicon"></i>
                    </a>
                    <div class="card-body">
                    <p class="card-title">${title}</p>
                    <p class="card-text">${description}</p>
                    </div>
                </div>`;
	div.innerHTML = card;
	var object = new CSS3DObject(div);
	object.position.set(x, middle, y);
	object.scale.set(scale, scale, scale);

	object.lookAt(0, middle, 0);
	return object;
};

export { tiktok, yt, img, twitchChannel, music, sc, sp, text, link, ig };
