import React, { useEffect, useState } from 'react';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { OrbitControls } from '../../three/orbitcontrols.js';
// import { OrbitControls } from '../../three/betterOrbitControls';
import { CSS3DRenderer, CSS3DObject } from '../../three/CSS3DRenderer.js';
// import Visualizer from 'react-audio-visualizer/src/Visualizer.js';
// import './fade.css';
import * as Objects from './objects';
import {convertDMS} from '../../utils/tools';
import {coords} from '../../store/position';
import Expand from '../expand';
import { Vector3 } from 'three';

var camera, cssrenderer, cssScene, io, currentCameraMovement;
var lastPosition = { x: 0, y: 0 };
var WIDTH = window.innerWidth;
var HEIGHT = window.innerHeight;
var itemStore = {};
// camera
var VIEW_ANGLE = 45;
var ASPECT = WIDTH / HEIGHT;
var NEAR = 1;
var FAR = 2000;
var cameraControls;
let setItem;
let audioPlaying = new Set();

let clickBlocker;

let users = {};
const userMove = (data) => {
	// console.log(data);
	if (!users[data.userId]) {
		users[data.userId] = {};
		var div = document.createElement('div');
		div.id = 'user_' + data.userId;
		div.className = 'userCircle fade-in';
		// div.style.backgroundColor = '#'+Math.floor(Math.random()*16777215).toString(16);
		div.innerHTML = `<span>${data.userId}</span>`;
		// div.style.opacity = 0.5;
		var object = new CSS3DObject(div);
		object.position.set(0, 0, 0);
		object.rotation.x = -Math.PI / 2;
		object.scale.set(0.25, 0.25, 0.25);
		users[data.userId] = object;
		cssScene.add(object);
	}

	users[data.userId].position.set(data.x, 0, data.z);
};

const userRemove = ({ userId }) => {
	if (users[userId]) {
		var div = document.getElementById('user_' + userId);
		div.classList.add('fade-out');
		setTimeout(() => {
			cssScene.remove(users[userId]);
			delete users[userId];
		}, 2001);
	}
};

const init = (setCoords, socket, setCurrentItem) => {
	io = socket;
	setItem = setCurrentItem;
	io.on('userMove', userMove);
	io.on('userRemove', userRemove);
	io.on('addItem', addItemtoScene);
	io.on('removeItem', removeItemFromScene);
	io.on('fade', fade);
	var container = document.getElementById('canvas');
	cssScene = new THREE.Scene();
	cssrenderer = new CSS3DRenderer();
	cssrenderer.setSize(WIDTH, HEIGHT);
	container.appendChild(cssrenderer.domElement);

	const loader = new THREE.TextureLoader();
	const bgTexture = loader.load('/images/sky.jpg');
	cssScene.background = bgTexture;
	// camera
	camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR);
	// camera.up.set(0,0,-1);
	camera.position.set(0, 100, 400);
	cameraControls = new OrbitControls(camera, cssrenderer.domElement);
	cameraControls.enableKeys = true;
	cameraControls.keys = {
		LEFT: 65,
		UP: 87,
		RIGHT: 68,
		BOTTOM: 83
	};
	cameraControls.keyPanSpeed = 55;
	cameraControls.target.set(0, 40, 0);
	cameraControls.maxDistance = 400;
	cameraControls.minDistance = 10;
	cameraControls.enableZoom = false;
	cameraControls.update();
	createFloor();
	createBox();
	listeners();
	animate();
};

const listeners = () => {
	var blocker = document.getElementById('blocker');
	blocker.style.display = 'none';
	document.addEventListener('mousedown', function() {
		clickBlocker = setTimeout(() => {
			blocker.style.display = '';
		}, 100);
	});
	document.addEventListener('mouseup', function() {
		clearTimeout(clickBlocker);
		blocker.style.display = 'none';
	});
	document.addEventListener(
		'contextmenu',
		function(e) {
			e.preventDefault();
		},
		false
	);
	window.addEventListener('resize', onWindowResize);
};

const tearDown = () => {
	//tear down viewer
};

const createBox = () => {
	fetch(`/api/fade?lat=${camera.position.x}&lon=${camera.position.y}`)
		.then((resp) => {
			return resp.json();
		})
		.then((photos) => {
			photos.forEach((item) => {
				itemStore[item.id] = item;
				addItemtoScene(item);
			});
		});
};

const removeItemFromScene = (item) => {
	var object = cssScene.getObjectByName(item.id);
	let el = object.element;
	el.classList.add('fade-out');
	setTimeout(()=>{
		cssScene.remove(object);
	},2000)
}



const addItemtoScene = (item) => {
	const {type} = item;
	if (!item || !type) {
		return;
	}
	if(Objects[type]) {
		let object = Objects[type](item, onPlay);
		object.name = item.id;
		object.element.id = "item_"+item.id;
		object.element.onmouseover = (e) => {
			let el = getContainer(e.target);
			let id = el.id.replace("item_",'');
			if(id && itemStore[id]) {
				console.log(el)
				removeActive();
				for(let child of el.children) {
					if(child.classList.contains("commentsTitle") === true) {
						console.log(child);
						child.classList.add('show');
					}
				}
				setItem(itemStore[id]);
			}
		}

		// object.element.onmouseout = (e) => {
		// 	// console.log("mouse out",e)
		// 	setItem(null);
		// }
		cssScene.add(object);
	} else {
		console.log("unknown type: ",item);
	}
};

const removeActive = () => {
	let elems = document.getElementsByClassName("show");

	for (let el of elems) {
		el.classList.remove("show");
	};
}

const getContainer = (el) => {
	if(el.id.indexOf('item_')===0) {
		return el;
	} else {
		return getContainer(el.parentNode);
	}
}

const fade = () => {
	console.log('fade triggered');
	let elm = document.getElementById('fade');
	let sound = document.getElementById("fadeAudio");
	sound.volume = .5;
	sound.play();
	if (elm.className.indexOf('fade') < 0) {
		return (elm.className = 'fade');
	}
	var newone = elm.cloneNode(true);
	elm.parentNode.replaceChild(newone, elm);
};

const createFloor = () => {
	var div = document.createElement('div');
	div.className = 'grid';
	div.style.width = '10000px';
	div.style.height = '10000px';
	div.style.opacity = 0.5;
	var object = new CSS3DObject(div);
	object.position.set(0, 0, 0);
	object.rotation.x = Math.PI / 2;
	cssScene.add(object);

	var div2 = document.createElement('div');
	div2.id = 'fade';

	div2.style.width = '20000px';
	div2.style.height = '20000px';
	// div2.className = 'fade';
	// div2.style.display = 'none';
	// div2.style.opacity = 0.5;
	var object2 = new CSS3DObject(div2);
	object2.position.set(0, 0, 0);
	object2.rotation.x = Math.PI / 2;
	cssScene.add(object2);

	var div3 = document.createElement('div');
	div3.id = 'selector';
	var object3 = new CSS3DObject(div3);
	object3.name = 'selector';
	object3.position.set(0, 0, 0);
	object3.rotation.x = Math.PI / 2;
	cssScene.add(object3);
};

const Viewer = (props) => {
	const { setCoords, socket, newItem } = props;
	const [currentItem,setCurrentItem] = useState(null)
	const [expanded,setExpanded] = useState(false);

	

	const updateItem = (item) =>{
		const {id} = item
		if(itemStore[id]) {
			itemStore[id] = item;
			if(currentItem && currentItem.id === id) {
				setItem(itemStore[id]);
			}
		}
	}

	socket.on('updateItem', updateItem);

	



	useEffect(() => {
		init(setCoords, socket, setCurrentItem);

		return tearDown;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() => {
			// console.log('---->', newItem);
			itemStore[newItem.id] = newItem;
			addItemtoScene(newItem);
		},
		[ newItem ]
	);

	const getIcon = (type) => {
		switch (type) {
			case 'ig' : 
				return 'fab fa-2x fa-instagram';
			case 'yt' : 
				return 'fab fa-2x fa-youtube';
			case 'img': 
				return 'far fa-2x fa-image';
			case 'twitchChannel':
			case 'twitch':
				return 'fab fa-2x fa-twitch';
			case 'link':
				 return 'fas fa-2x fa-link';
			case 'sc':
				return 'fab fa-2x fa-soundcloud';
			case 'sp': 
				return 'fab fa-2x fa-spotify';
			case 'text':
				return 'fas fa-2x fa-font';
			case 'music':
				return 'fas fa-2x fa-music';
			case 'tiktok': 
				return 'tiktokIcon'; //@todo replace with real tik tok icon
			default:
				break
		}
	}

	const defend =  id => ()=> {
		let url = `/api/fade/${id}`;
		fetch(url,{
			method:'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
		})
		.then((resp) => {
			return resp.json();
		})
		.then((item) => {
			let tmp = {...currentItem};
			tmp.def = item.def;
			tmp.userDef = item.userDef;
			setCurrentItem(tmp);
		});
	}

	const walk = () => {
		if(currentCameraMovement && currentCameraMovement.stop) {
			currentCameraMovement.stop();
			TWEEN.remove(currentCameraMovement);
		}

		let object =  cssScene.getObjectByName(currentItem.id);
    	let sinOfAngleX = Math.abs(currentItem.lon) / currentItem.r; 
		let theta = Math.asin(sinOfAngleX)  * 180/Math.PI;
		let posX = (currentItem.r - 150) * Math.cos(theta) * (currentItem.lat<0?-1:1);
		let posY = posX * Math.sin(theta) * (currentItem.lon<0?-1:1);
		// console.log(cameraControls.target);
		// console.log(currentItem.lat,currentItem.lon);

		if(currentItem.lon === 0) {
			posY = 0;
			posX = currentItem.lat - 150;
		}

		if(currentItem.lat === 0) {
			posX = 0;
			posY = currentItem.lon + 150;
		}

		// console.log(sinOfAngleX,posX,posY,currentItem.lon,currentItem.lat);
		var from = {
			x : cameraControls.target.x,
			y : cameraControls.target.y,
			z : cameraControls.target.z,
			cX: camera.position.x,
			cY: camera.position.y,
			cZ: camera.position.z

		  };
	
		  var to = {
			x : currentItem.lat,
			y : cameraControls.target.y,
			z : currentItem.lon,
			cX: posX,
			cY: camera.position.y,
			cZ: posY
		  };
		  currentCameraMovement = new TWEEN.Tween(from)
		  .to(to,5000)
		  .easing(TWEEN.Easing.Quintic.InOut) //TWEEN.Easing.Quintic.InOut TWEEN.Easing.Quadratic.InOut
		  .onUpdate(function () {
			//   console.log(from.x,from.y,from.z)
			  cameraControls.target = new Vector3(from.x, from.y, from.z);
			  camera.position.set(from.cX, from.cY, from.cZ);
			// camera.lookAt(object.position);
			// OrbitControls.target(object.position)
		  })
		  .onComplete(function () {
			camera.lookAt(object.position);
			// OrbitControls.target(object.position)
		  })
		  .start();
	}

	const expand = ()=> {
		setExpanded(!expanded);
	}
	const bookmark = ()=> {
		// setExpanded(!expanded);
	}
	const comments = ()=> {
		// setExpanded(!expanded);
	}
	const share = ()=> {
		// setExpanded(!expanded);
	}

	return (
		<div className="container" style={{position:'relative'}}>
			<div id="canvas" />
			<div id="blocker" />
			{(currentItem !== null)?
			<div id="info">
				<div className="map" onClick={walk} ><i className="fas fa-walking fa-2x"></i></div>
				<div className={"src " +  currentItem.type}><i className={getIcon(currentItem.type)}></i></div>
				<div className="title">
					<span>{currentItem.data.title || currentItem.url}</span>
					<div className="actions">
						<a href="#expand" onClick={comments}><i className="fas fa-comment-dots"></i></a>
						<a href="#expand" onClick={share}><i className="far fa-compass"></i></a>
						<a href="#expand" onClick={bookmark}><i className="far fa-bookmark"></i></a>
						<a href="#expand" onClick={expand}><i className="fas fa-expand-alt"></i></a>
					</div>
				</div>
				<div className="def" onClick={defend(currentItem.id)}>
					<span className={(currentItem.userDef === true)?"super red":"super"}><i className="fas fa-shield-alt"></i></span>
					<span>{Math.ceil(currentItem.def)}</span>
				</div>
			</div>:null}
			{expanded && <Expand data={currentItem} onClose={expand} />}
			<audio id="fadeAudio" src="/fade.mp3"></audio>
		</div>
	);
};

export default Viewer;

function onWindowResize() {
	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	cssrenderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
	requestAnimationFrame(animate);
	
	TWEEN.update();
	if (camera.position.y <= 1) {
		camera.position.y = 1; //don't allow the camera to go below the floor.
	}

	if (camera.position.x !== lastPosition.x || camera.position.z !== lastPosition.y) {
		// console.log('position update', camera.position);
		io.emit('positionUpdate', camera.position);
		lastPosition = { x: camera.position.x, y: camera.position.z };
		// console.log(audioPlaying)
		if (audioPlaying.size > 0) {
			audioPlaying.forEach((a) => {
				const { x: x2, z: y2 } = camera.position;
				const { x, y } = a;

				let xDif = diff(x, x2);
				let yDif = diff(y, y2);
				let totalDif = Math.hypot(xDif, yDif);
				let vol = totalDif / 1500;
				if (Math.abs(vol) > 1) {
					vol = 1;
				}
				vol -= 1;
				vol = Math.abs(vol);
				if (vol <= 0) {
					vol = 0;
					a.src.pauseSound();
					audioPlaying.delete(a);
				}
				a.src.volume(vol);
			});
		}
		let el = document.getElementById('coordsHud');
		el.innerHTML = convertDMS(camera.position.x, camera.position.z);
		coords.x = camera.position.x;
		coords.y = camera.position.z;
		coords.x1 = cameraControls.target.x;
		coords.y1 = cameraControls.target.z;
	}
	cameraControls.update();
	cssrenderer.render(cssScene, camera);
}

const onPlay = (src) => {
	let { dataset } = src.audio;
	let x = Number(dataset.x);
	let y = Number(dataset.y);
	audioPlaying.add({ x, y, src });
};

const diff = (num1, num2) => {
	if (num1 > num2) {
		return num1 - num2;
	} else {
		return num2 - num1;
	}
};
