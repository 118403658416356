import React, { useState } from 'react';
import './style.css';

const Dialog = (props) => {
	const { onClose } = props;
	const [ user, setUser ] = useState('');
	const [ pass, setPass ] = useState('');

	// const login = () => {
	// 	console.log(user,pass);
	// }

	const login = (e,v) => {
		e.preventDefault();
		const body = {
			username:user,
			password:pass
		};
		fetch('/api/login',{
		  method: 'post',
		  headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		  },
		  body: JSON.stringify(body)
		}).then(resp=>{
			  if (!resp.ok) {
				  throw resp;
			  }
			return resp.json();
		}).then(user=>{          
			localStorage.setItem('user',JSON.stringify(user));
			onClose(user);
			// window.location.href = '/';
		}).catch(e => {
			return e.json();
		}).then(e =>{
			
		});
		return false;     
	}

	return (
		<div id="myModal" className="modal">
            <div className="modalClose" onClick={onClose}></div>
			<div className="modal-login">
				{/* <span className="close" onClick={onClose}>&times;</span> */}
				<div>
					<img src="/images/logo.png" alt="logo" className="logo" />
				</div>
				<div>
					Welcome To Fade
				</div>
				<div>
					<input
						type="text"
						name="user"
						onChange={(e) => {
							setUser(e.target.value);
						}}
						placeholder="Email or Username"
						className="logininput"
					/>
					<br/>
					<input
						type="password"
						name="pass"
						onChange={(e) => {
							setPass(e.target.value);
						}}
						placeholder="Password"
						className="logininput"
					/>
					<div className="loginbtn" onClick={login}>Login</div>
					<div>Or</div>
					<div className="social">
						<span>
							<a href="/api/twitter" ><img src="/images/facebook.svg" alt="facebook" /></a>
						</span>
						<span>
							<a href="/api/twitter" ><img src="/images/twitter.svg" alt="twitter" /></a>
						</span>
						<span>
							<a href="/api/instagram" ><img src="/images/instagram.svg" alt="instagram" /></a>
						</span>
						<span>
							<a href="/api/twitter" ><img src="/images/snapchat.svg" alt="snapchat" /></a>
						</span>
						<span>
							<a href="/api/twitter" ><img src="/images/tiktok.svg" alt="tik tok" /></a>
						</span>
					</div>
                    <div>Create Account</div>
				</div>
			</div>
		</div>
	);
};

export default Dialog;
