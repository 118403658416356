function convertDMS( lng, lat ) {
 
    var convertLat = Math.abs(lat-400);
    if(convertLat <5) {
      convertLat = 0;
    }
    var LatDeg = Math.floor(convertLat);
    var LatMin = (Math.floor((convertLat - LatDeg) * 60));
    var LatCardinal = ((lat > 0) ? "N" : "S");
     
    var convertLng = Math.abs(lng);
    var LngDeg = Math.floor(convertLng);
    var LngMin = (Math.floor((convertLng - LngDeg) * 60));
    var LngCardinal = ((lng > 0) ? "E" : "W");
     
    return LatDeg + '° ' + LatMin  + "' " + LatCardinal + "  " + LngDeg + '° ' + LngMin + "' " + LngCardinal;
  }


  export {convertDMS}