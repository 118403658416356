import React from 'react';
import './style.css'



const Hud = (props) => {
    const {user, addStory, addLogin} = props;
    const circle = () => {
        if(user.avatar) {
            return <div className="avatar" >{user.userId.charAt(0)}</div>
        } else {
            return <div className="avatar" ><span>{user.userId.charAt(0)}</span></div>
        }
    }
    return <div className="userMenu">
        <div className="button" onClick={addStory}> 
            <span>+ Add Story</span>
        </div>
        {(user)? 
        circle():
        <div className="button" onClick={addLogin}><span>Login / Register</span></div>}        
    </div>
}

export default Hud;