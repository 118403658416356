import React, {useState, useEffect} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const MyEditor = ({onChange}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
  useEffect(()=>{
    let temp = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    onChange(temp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editorState]);
  return <Editor
  editorState={editorState}
  toolbar={ {options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji']}}
  onEditorStateChange={setEditorState}
/>;
}


export default MyEditor;